.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #333333;
}
/* 
.App .gif {
  width: 60%;
  height: auto;
  max-height: 80vh;
}

.led {
  width: 60%;
  overflow: hidden;
  background-color: #333;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  color: #ff0000;
  font-family: serif;
}

.led:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url("http://on-ze.com/demo/css3-led-panel/bg-led.png") repeat
    scroll 0 0 rgba(0, 0, 0, 0);
  -webkit-background-size: 4px 4px;
  -moz-background-size: 4px 4px;
  -ms-background-size: 4px 4px;
  -o-background-size: 4px 4px;
  background-size: 4px 4px;
  z-index: 10;
}

.led p {
  margin: 0;
  padding-left: 600px;
  display: inline-block;
  white-space: nowrap;
  box-sizing: border-box;
  -webkit-animation-name: marquee;
  -webkit-animation-timing-function: linear;
  -webkit-animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-name: marquee;
  -moz-animation-timing-function: linear;
  -moz-animation-duration: 7s;
  -moz-animation-iteration-count: infinite;
  -ms-animation-name: marquee;
  -ms-animation-timing-function: linear;
  -ms-animation-duration: 7s;
  -ms-animation-iteration-count: infinite;
  -o-animation-name: marquee;
  -o-animation-timing-function: linear;
  -o-animation-duration: 7s;
  -o-animation-iteration-count: infinite;
  animation-name: marquee;
  animation-timing-function: linear;
  animation-duration: 7s;
  animation-iteration-count: infinite;
}
@-webkit-keyframes marquee {
  from {
    -webkit-transform: translate(0%);
  }
  99%,
  to {
    -webkit-transform: translate(-100%);
  }
}
@-moz-keyframes marquee {
  from {
    -moz-transform: translate(0%);
  }
  99%,
  to {
    -moz-transform: translate(-100%);
  }
}
@-ms-keyframes marquee {
  from {
    -ms-transform: translate(0%);
  }
  99%,
  to {
    -ms-transform: translate(-100%);
  }
}
@-o-keyframes marquee {
  from {
    -o-transform: translate(0%);
  }
  99%,
  to {
    -o-transform: translate(-100%);
  }
}
@keyframes marquee {
  from {
    transform: translate(0%);
    opacity: 1;
  }
  50% {
    transform: translate(-100%);
    opacity: 1;
    border: unset;
  }
  51% {
    transform: translate(0%);
    opacity: 0;
    padding-left: 0;
    text-align: center;
    border: 5px solid red;
    border-style: dashed;
    width: 100%;
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    color: red;
  }
  75% {
    opacity: 1;
    color: blue;
  }
  80% {
    color: red;
  }
  85% {
    color: blue;
  }
  95% {
    opacity: 0;
    color: red;
    border: 5px solid red;
    border-style: dashed;
    color: blue;
  }
  99% {
    opacity: 1;
    transform: translate(0%);
    color: red;
    border: unset;
  }
  to {
    opacity: 1;
    padding-left: 0;
    transform: translate(-100%);
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .App .gif {
    max-height: 90vh;
  }
}

@media screen and (max-width: 600px) {
  .App .gif {
    width: 100%;
  }

  .led {
    width: 100%;
  }
} */
