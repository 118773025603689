@font-face {
  font-family: 'EuropeanTeletext';
  src: url('/public/fonts/EuropeanTeletext.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}  

.teletext-container {
  font-family: 'EuropeanTeletext', 'Courier New', 'Liberation Mono', monospace;
  background-color: #000;
  color: #fff;
  padding: 20px;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.teletext-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
}

.page-number {
  font-weight: bold;
}

.teletext-title {
  color: #00ff00;
  text-align: center;
  margin: 20px 0;
  font-size: 2em;
}

.teletext-row {
  margin: 10px 0;
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.selected {
  background-color: #0066cc;
  color: white;
}

.selected .teletext-number,
.selected .teletext-text {
  color: white;
}

.teletext-number {
  color: #ffff00;
}

.teletext-text {
  color: #fff;
}

.teletext-footer {
  margin-top: auto;
  padding-top: 10px;

  & > p {
    text-align: center;
    margin: 16px 0 ;
  }
}

.color-buttons {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  border-top: 2px solid #fff;
}

.red-button {
  color: #ff0000;
  cursor: pointer;
}

.green-button {
  color: #00ff00;
  cursor: pointer;
}

.yellow-button {
  color: #ffff00;
  cursor: pointer;
}

.blue-button {
  color: #0000ff;
  cursor: pointer;
}

/* Yanıp sönen imleç efekti */
@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.teletext-cursor {
  animation: blink 1s infinite;
  display: inline-block;
}

.page-reference {
  font-family: inherit;
  letter-spacing: -1px;
}

.name-container {
  background-color: #ff0000;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.name-container .teletext-title {
  margin: 0;
  color: #ffffff;
  font-size: 3.5rem;
}

.name-container .teletext-title span {
  color: #ff0000;
  background-color: #fff;
  margin: 0 4px;
}

.teletext-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.content-row {
  display: flex;
  gap: 40px;
}

pre {
  margin: 0;
  align-self: center;
  flex: 1;
  font-size: 3px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: colorChange 12s infinite;
}

@keyframes colorChange {
  0% { color: #9ACD32; }
  33% { color: #FF69B4; }
  66% { color: #4169E1; }
  100% { color: #9ACD32; }
}

.menu-section {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.name-container {
  width: 100%;
} 